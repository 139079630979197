import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { AuthenticatedLayout, RoleLayout, UnauthenticatedLayout, ActivationLayout } from 'domain/app';
import { DASHBOARD_ROUTES, DashboardPage } from 'domain/dashboard';
import { AUTH_ROUTES, LoginPage, LogoutPage, ForgotPasswordPage, RegisterPage } from 'domain/auth';
import { COMMON_ROUTES, UnauthorisedPage } from '@when-fertility/shared/domain/common';
import { ActivatePage, ACTIVATE_ROUTES } from 'domain/activate';
import { QUIZ_ROUTES, QuizCompletePage, QuizPage } from 'domain/quiz';
import { USER_ROUTES, UserProfilePage } from 'domain/user';
import { RESULT_ROUTES, ResultsPage, MemberReportPage } from 'domain/results';
import { DateOfBirthPage } from './domain/activate/date-of-birth.page';

export const App = () => {
  return (
    <main className="bg-silver-40 min-h-screen relative font-abc-diatype text-charcoal-100">
      <Routes>
        <Route element={<ActivationLayout />}>
          <Route path={ACTIVATE_ROUTES.root} element={<ActivatePage />} />
        </Route>
        <Route element={<UnauthenticatedLayout />}>
          <Route path={AUTH_ROUTES.logout} element={<LogoutPage />} />
          <Route path={AUTH_ROUTES.login} element={<LoginPage />} />
          <Route path={AUTH_ROUTES.forgotPassword} element={<ForgotPasswordPage />} />
          <Route path={AUTH_ROUTES.register} element={<RegisterPage />} />
        </Route>
        <Route element={<AuthenticatedLayout />}>
          <Route element={<RoleLayout role="PATIENT" />}>
            {/* We need all pages to live within a role layout to be able to query for the user role - to display the correct nav. 
                Will work with this under either the nurse or patient RoleLayout
            */}
            <Route path={COMMON_ROUTES.unauthorised} element={<UnauthorisedPage />} />
            <Route path={ACTIVATE_ROUTES.dob} element={<DateOfBirthPage />} />
            <Route path={DASHBOARD_ROUTES.root} element={<DashboardPage />} />
            <Route path={RESULT_ROUTES.root} element={<ResultsPage />} />
            <Route path={RESULT_ROUTES.report} element={<MemberReportPage />} />
            <Route path={QUIZ_ROUTES.root} element={<QuizPage />} />
            <Route path={QUIZ_ROUTES.complete} element={<QuizCompletePage />} />
            <Route path={USER_ROUTES.profile} element={<UserProfilePage />} />
          </Route>
          <Route path="/" element={<Navigate to={DASHBOARD_ROUTES.root} />} />
        </Route>
      </Routes>
    </main>
  );
};
